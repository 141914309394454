import './custom.scss';
import { Suspense, lazy } from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import Spinner from "./common/components/Spinner";

const Layout = lazy(() => import('./components/Layout'));
const AppRoutes = lazy(() => import('./AppRoutes'));


const oktaAuthModel = new OktaAuth({
  issuer: `${process.env.REACT_APP_OKTA_URL}/oauth2/default`,
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  redirectUri: window.location.origin + '/login/callback',
  postLogoutRedirectUri: window.location.origin,
  scopes: ['openid', 'profile', 'email'],
  pkce: true
});

const App = props => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuthModel} restoreOriginalUri={restoreOriginalUri}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <AppRoutes />
        </Layout>
      </Suspense>
    </Security>
  )
};

const AppWithRouterAccess = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouterAccess;